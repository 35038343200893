import { computed, observable, action } from 'mobx'
import { getCookies } from '../store/useCookies';
export default class RechargeDeviceActionMobx {
    @observable editEachRecharge = {}

    constructor() {
		
    }
	
	@action
    setEditEachRecharge(data) {
        this.editEachRecharge = data
	}
    @computed
    get getEditEachRecharge() {
        return this.editEachRecharge
	}
}