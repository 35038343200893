import "./style";
import App from "./App";
import { StoreProvider } from "./helpers/store-provider";
import UserMobx from "./mobx/UserMobx";
import MenuMobx from "./mobx/MenuMobx";
import RechargeDeviceActionMobx from "./mobx/RechargeDeviceActionMobx";

const Wrap = () => {
  return (
    <StoreProvider
      value={{
        user: new UserMobx(),
        menu: new MenuMobx(),
        rechargeDevice: new RechargeDeviceActionMobx(),
      }}
    >
      <App />
    </StoreProvider>
  );
};

export default Wrap;
