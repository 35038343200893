import { computed, observable, action } from 'mobx'
import { getCookies } from '../store/useCookies';
export default class UserMobx {
    @observable isLogin = true
    @observable user = {}
    @observable customer_id = ''
    @observable remaining_credit = 0
	
    constructor() {
        this.onInitial();
    }

    @action
    onInitial() {
        try {
			// this.setCheckLogin(true)
			getCookies('token_hdp') ? this.setCheckLogin(true) : this.setCheckLogin(false)
        } catch (error) {
			
        }
    }
	
	@action
    setCheckLogin(data) {
        this.isLogin = data
	}
	
	@action
    setUser(data) {
        this.user = data
    }
	

	@action
    setCustomerId(data) {
        this.customer_id = data
    }

	@action
    setRemainingCredit(data) {
        this.remaining_credit = data
    }

	@computed
    get getRemainingCredit() {
        return this.remaining_credit;
        // return this.isLogin
	}

	@computed
    get getCustomerId() {
        return this.customer_id;
        // return this.isLogin
	}

    @computed
    get onCheckLogin() {
        return getCookies('token_hdp') ? true : false
        // return this.isLogin
	}



	@computed
    get getUser() {
        return this.user
    }

}