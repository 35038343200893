import { Route, route, Router } from "preact-router";
import RootRoute from "./routes/Root";
import AuthenticatedRoute from "./routes/AuthenticatedRoute";
import NotfoundScene from "./screens/NotfoundScene";
import AccountRoute from "./routes/AccountRoute";
import { useStore } from "./helpers/use-store";
import "./style/index.scss";
import GA4React from "ga-4-react";

let ga4 = "";
const ga4react = new GA4React("G-9RN5BXQV4V");

(async () => {
  ga4 = await ga4react.initialize();
})();

const App = () => {
  async function handleRouter(e) {
    const { user } = useStore();
    const allowed = user.onCheckLogin;

    if (!allowed) {
      if (e.url.indexOf("account") >= 0) {
        return route(e.url);
      }
      return route("/account/login");
    }
    if (e.url.indexOf("/account/") >= 0) return route("/");

    /** google analytic */
    const { pathname } = window.location;
    {
      ga4 && (await ga4.pageview(pathname));
    }
  }

  return (
    <div>
      <Router onChange={(e) => handleRouter(e)}>
        <AccountRoute path="/account/:rest*" />
        <AuthenticatedRoute path="/:rest*" login="/" route={RootRoute} />
        <Route path="*" default component={NotfoundScene} />
      </Router>
    </div>
  );
};

export default App;
